@import './breakpoint.scss';
@import './colors.scss';

@mixin responsive($breakpoint) {
    @if $breakpoint==mobile {
      @media #{$mobile} {
        @content;
      }
    }
  
    @else if $breakpoint==tablet {
      @media #{$tablet} {
        @content;
      }
    }
  
    @else if $breakpoint==small {
      @media #{$small} {
        @content;
      }
    }
  
    @else if $breakpoint==medium {
      @media #{$medium} {
        @content;
      }
    }
  
    @else if $breakpoint==large {
      @media #{$large} {
        @content;
      }
    }
  }
  @mixin image-logo {
    margin: 20px;
    border-radius: 50%;
    box-shadow: 3px 5px 10px rgba(0, 0, 0, 0.2);
  }

  @mixin image-size-large {
    width: 300px;
    height: 300px;
    @include image-logo();
  }
  
  @mixin image-size-small {
    width: 90px;
    height: 90px;
    @include image-logo();
  }
  
  @mixin absolute-bottom($bottom) {
    position: absolute;
    bottom: $bottom;
  }
  
  @mixin main-margin {
    margin-top: 80px;
  }
  @mixin margin-top-high {
    margin-top: 60px;
  }

  @mixin margin-vertical-small {
    margin: 30px 0;
  }

  @mixin align-right {
    text-align: right;
  }
  
  @mixin align-justify {
    text-align: justify;
  }
  
  @mixin align-center {
    text-align: center;
  }
  
  @mixin bold-text {
    font-weight: 700;
  }
  
  @mixin border-radius-input {
    border-radius: 8px;
  }
  
  @mixin border-radius-btn {
    border-radius: 10px;
  }
  
  @mixin transparent-blur {
    background-color: rgba(255, 255, 255, .1);
    border-radius: 25px;
    backdrop-filter: blur(4px);
    box-shadow: -1px -2px 5px rgba(255, 255, 255, .3), 1px 2px 8px rgba(0, 0, 0, .3);
  }

  @mixin box-shadow {
    box-shadow: 1px 0 7px rgba(255, 255, 255, .4);
  }

  @mixin box-shadow-custom($top, $side, $size, $r, $g, $b, $a) {
    box-shadow: $top+px $side+px $size+px rgba($r, $g, $b, $a);
  }
  
  @mixin form-input {
    height: 35px;
    border: solid 1px $greyPrimary;
    border-color: $greyPrimary;
    box-shadow: none;
    @include border-radius-input();
  }  
