/* Color Theme Swatches in Hex */
$greenDark: #004747;
$greenPrimary: #35ab82;
$greenLight: #8ADFC1;
$bluePrimary: #1285fd;
$orangePrimary: #f8a000;
$orangeLightColor: #f89f00;
$redPrimary: #ef1033;
$greyPrimary: #c8cacb;
$greyIconInput: #979797;
$greyDark: #505050;
$greyLight: #dddddd;
$greyWhite: #FCFCFC;
$white: #ffffff;
$darkColor: #121422;
$greyOpacity: rgba(240, 240, 240, .43);

$hot-temperatures-1-hex: #F5D600;
$hot-temperatures-2-hex: #F5AB01;
$hot-temperatures-3-hex: #F07D01;
$hot-temperatures-4-hex: #F54900;
$hot-temperatures-5-hex: #FA1200;

/* Color Theme Swatches in RGBA */
$hot-temperatures-1-rgb: rgb(245,214,0);
$hot-temperatures-2-rgb: rgb(245,171,1);
$hot-temperatures-3-rgb: rgb(240,125,1);
$hot-temperatures-4-rgb: rgb(245,73,0);
$hot-temperatures-5-rgb: rgb(250,18,0);

/* Color Theme Swatches in Hex */
$median-temperatures-1-hex: #99F500;
$median-temperatures-2-hex: #54F000;
$median-temperatures-3-hex: #23E800;
$median-temperatures-4-hex: #08E300;
$median-temperatures-5-hex: #00DE0F;

/* Color Theme Swatches in RGBA */
$median-temperatures-1-rgba: rgba(153,245,0, 1);
$median-temperatures-2-rgba: rgba(84,240,0, 1);
$median-temperatures-3-rgba: rgba(35,232,0, 1);
$median-temperatures-4-rgba: rgba(8,227,0, 1);
$median-temperatures-5-rgba: rgba(0,222,15, 1);

/* Color Theme Swatches in Hex */
$freeze-temperatures-1-hex: #01F5EA;
$freeze-temperatures-2-hex: #00CEF5;
$freeze-temperatures-3-hex: #0190F5;
$freeze-temperatures-4-hex: #0058F5;
$freeze-temperatures-5-hex: #0021FA;

/* Color Theme Swatches in RGBA */
$freeze-temperatures-1-rgba: rgba(1,245,234, 1);
$freeze-temperatures-2-rgba: rgba(0,206,245, 1);
$freeze-temperatures-3-rgba: rgba(1,144,245, 1);
$freeze-temperatures-4-rgba: rgba(0,88,245, 1);
$freeze-temperatures-5-rgba: rgba(0,33,250, 1);
