@import './breakpoint.scss';
@import './colors';
@import './mixin.scss';


ul {
  margin: 0;
  padding: 0;

  li {
    list-style-type: none;
  }
}

.red-color {
  color: $redPrimary;
}

.white-text {
  color: $white;
}

.grey-text {
  color: $greyDark;
}

.align-center {
  text-align: center;
}

.large-font-text {
  font-size: 22px;
}

.map-container {
  height: 400px;
  width: 100%;
  border: solid 1px $greyIconInput;
}

.sidebar {
  background-color: rgba(35, 55, 75, 0.9);
  color: $white;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  border-radius: 4px;
}

.marker {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  cursor: pointer;
}

.tooltip {
  position: absolute;
  margin: 8px;
  padding: 14px;
  background: rgba(255, 255, 255, 0.8);
  color: #333;
  max-width: 300px;
  font-size: 12px;
  z-index: 9;
}
.tip {
  padding: 14px;
  background: rgba(255, 255, 255, 0.8);
  color: #333;
  max-width: 300px;
  font-size: 12px;
  z-index: 9;
}

.home-bg {
  background-image: url(../images/bg-home.jpeg);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;

  .logo-img {
    width: 40%;
    margin: 10px auto;
  }

  .button-link {
    border-radius: 10px;
    margin: auto;
  }

}