@import './mixin.scss';
@import './colors.scss';

.form-container {
  @include responsive(mobile) {
    display: flex;
    justify-content: center;
  }
  @include responsive(small) {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .form-block {
    padding-top: 36px;
    @include responsive(mobile){
      width: 90%;
      max-width: 400px;
    }
    @include responsive(tablet){
      min-width: 350px;
    }

    .form {
      width: 100%;
      .form-input {
        .ant-input-number {
          background: none;
        }
      }
      .form-input:not(.ant-input-number), .ant-select-selector, .ant-select-focused .ant-select-selector {
        @include form-input();
        width: 100%;
        input {
          outline: none;
          border-color: violet;
          box-shadow: none;
        }
      }
      
      .ant-form-item-explain {
        font-size: 11px;
        color: #fff;
        text-align: justify;
        margin: 10px 0;
      }
      .ant-btn {
        height: 35px;
        color: #fefefe;
        font-weight: bold;
        font-size: 15px;
        font-family: 'fontopen';
        transition: .4s;
        @include border-radius-btn();
        &.cancel {
          color: black;
        }

        @include responsive(small) {
          width:110px;
        }
        @include responsive(medium) {
          width:115px;
        }
        @include responsive(large) {
          width:135px;
        }
      }
    }

  }
}

.label {
  font-size: 16px;
  line-height: 36px;
}


.valid-form-button {
  width: 167px;
  height: 35px;
  color: #fefefe;
  font-weight: bold;
  font-size: 15px;
  border-radius: 10px;


}

.form-button {
  align-self: center;
  margin-top: 20px;

  &.cancel {
    width: 100%;
  }
}

.form-button-cancel {
  display: flex;
  justify-content: space-between;

  .valid-form-button {
    width: 100px;
  }
}

