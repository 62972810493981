@import 'antd/dist/antd.css';
@import "react-slideshow-image/dist/styles.css";
@import './global.scss';

@keyframes arrowModal {
    from {
        transform: translateX(0px)
    }

    to {
        transform: translateX(-4px)
    }
}

.container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 40px;
    transition: .4s;
}

.white-color {
    color: $white;
}
.pdf-link {
    position: absolute;
    top: 0;
    left: 30%;
}
.marker-wind {
    font-size: 40px;
}

.winds {
    margin-top: 100px;
    align-items: center;

    .card {
        border-color: $greyPrimary;
        margin-top: 30px;
        border-radius: 10px;
        position: relative;
        width: 35%;
        min-width: 440px;
        
        .card-title {
            color: $greenPrimary;
            }
        .anticon {
            margin-left: 15px;
            }
        .active-icon {
            color: $greenPrimary;
            }
        .inactive-icon {
            color: $redPrimary;
        }
        .card-content {
            display: flex;
        }
        .card-infos {
            margin-right: 16px;
            flex: 1;
        }
        .card-map {
            width: 80%;
            max-width: 200px;
            max-height: 250px;
            border-radius: 5px;
            overflow: hidden;
        }
    }
}

.domains {
    position: absolute;
    left: 50%;
    top: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
}

.domain-picture-small {
    @include image-size-small   
}

.domain-picture-large {
    @include image-size-large   
}

.fadeIn{
    transition: 0.3s ease-out;
 }
 
 .fadeOut {
     opacity: 0;
     transition: 0s ease-out;
 }
 
article {
    padding: 30px;
    max-width: 600px;
    min-width: 200px;
    max-height: 400px;
    margin: 40px auto;
    @include transparent-blur();
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    transition: .4s;

    .header-list {
        display: flex;
        align-items: center;

        h3 {
            margin: 0 20px 0;
        }

        svg {
            font-size: 24px;
            color: $greenDark ;
        }
    }

    table {
        .iconAction {
            color: $greenDark;
        }

        .disabled {
            color: $greyPrimary;
            cursor: not-allowed;
        }

        span {
            color: $greyDark;
        }
    }

    &.dashboard-bloc {
        max-width: 850px;
    }

    &.article-form {
        max-width: 400px;
    }

    .scrollable {
        width: 100%;
        max-height: 400px;
        overflow-y: auto;
    }

    .addTrace {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 13px;

        .add-icon {
            font-size: 22px;
            margin-left: 10px;
        }
    }
}

.lastImage {
    border-radius: 5px;
    margin-bottom: 15px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

a {
    margin: 0 auto;
}

.ant-form-item-explain div {
    color: $greyDark;
}

.ant-form-item-explain .ant-form-item-explain-error {
    color: $redPrimary;
}

.ant-btn-primary {
    background-color: $greenPrimary;
    border: none;

    &:hover {
        background-color: $greenPrimary;
        opacity: .6;
    }
}

.ant-tag {
    border-radius: 7px;
}

.ant-upload span {
    color: $greyDark;
}

.aside {
    position: fixed;
    height: 100vh;
    padding-top: 15px;
    border-right: solid 1px $greyLight;
    background-color: $greenPrimary;
    backdrop-filter: blur(5px);
    border: none;
    @include box-shadow;
    z-index: 200;

    .aside-header {
        text-align: center;
    }

    .aside-logo {
        margin-bottom: 10px;

        .menu-lang {
            display: inline-block;
            width: 45%;

            & span {
                font-size: 11px;
            }

            .anticon {
                font-size: 12px;
            }
        }
    }
    .aside-footer {
        position: absolute;
        bottom: 0;
        width: 100%;

        .footer-pictos {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 16px;

            svg {
                font-size: 20px;
                color: #004747;
                margin: 10px;
            }
          }
          .footer-pictos.collapsed {
            flex-direction: column;
          }
    }

    &.ant-layout-sider-collapsed {

        .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
            background-color: $greenLight;
            border-right: none;

            a {
                color: $white;
                @include bold-text;
            }
        }

        .aside-logo {
            width: auto;
            transition: .2s;
        }
    }

    p,
    span {
        color: $white;
    }

    .menu {
        border: none;
        color: $white;
        li:not(.ant-menu-item-selected) {
            a {
                color: $white;
                opacity: .85;
                @include bold-text;

                &:hover {
                    color: $white;
                }
            }
        }

        .anticon {
            display: inline-block;
            font-size: 20px;
            vertical-align: middle;
        }
    }

    .margin-top-high {
        @include margin-top-high;
    }

    .margin-vertical-small {
        @include margin-vertical-small;
    }

    .ant-menu-item {
        position: relative;
        display: inline-block;
        background-color: transparent;
        width: 100%;

        &.ant-menu-item-selected {
            &::after {
                border-right: none;
            }
        }
    }

    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
        position: relative;
        background-color: $greenLight;
        height: 40px;
        overflow: visible;
        border-radius: 35px 0 0 35px;

        &::after {
            content: '';
            width: 25px;
            height: 45px;
            right: 0px;
            top: -20px;
            background-color: $greenLight;
            z-index: 0;
        }

        .anticon {
            display: inline-block;

            &::after {
                content: '';
                position: absolute;
                right: 0;
                top: -35px;
                width: 35px;
                height: 35px;
                border-radius: 0 50% 50% 0;
                background-color: $greenPrimary;
                z-index: 1;
            }
        }

        a {
            color: $white;
            font-weight: 600;
        }

    }

    .ant-layout-sider-children {
        & .menu {
            background-color: transparent;
        }
    }

    .ant-layout-sider-zero-width-trigger {
        top: 20px;
        background: none;
        color: $darkColor;
    }
}

.private-layout {
    .private-content {
        min-height: 100vh;
        background-color: $greyWhite;
        transition: .4s;

        .main-container {
            transition: .2s;

            @include responsive(tablet) {
                padding-left: 210px;

                &.collapsed {
                    padding-left: 90px;
                }
            }
        }
    }
}

.switch-lang {
    position: absolute;
    top: 1%;
    left: inherit;
    @include transparent-blur();
    border-radius: 0 10px 10px 0;
    color: #fff;
    transform: translateX(-95px);
    transition: 0.2s;

    &.modal-open {
        transform: translateX(0px);
        transition: .2s;
    }

    .lang-picto {
        margin: 0 5px;
        cursor: pointer;
        animation: .4s ease-in-out alternate arrowModal;
        animation-iteration-count: 10;
    }
}

.map-content {
    position: absolute;
    width: 100%;
    right: 0;

    @include responsive(tablet) {
        margin-left: -210px;

        &.collapsed {
            margin-left: -90px;
        }
    }
}

.control-panel {
    position: absolute;
    top: 150px;
    right: 0;
    max-width: 520px;
    background-color: $greyOpacity;
    backdrop-filter: blur(8px);
    @include box-shadow-custom(0, 2, 4, 0, 0, 0, .3);
    padding: 12px 24px;
    border-radius: 20px 0 0 20px;
    font-size: 13px;
    line-height: 2;
    color: $greyDark;
    outline: none;
    z-index: 10;

    h2 {
        @include bold-text();
        color: $greenDark;
    }

    .filter-bloc {
        max-height: 200px;
        overflow: auto;
    }

    .picto-tag {
        margin-left: 10px;
    }

    .ant-radio-group {
        display: flex;
        flex-wrap: wrap;

        .ant-radio-button-wrapper {
            overflow: hidden;
            border-radius: 15px;
            margin: 3px;
            background-color: $greenPrimary;

            span {
                color: $white;
            }

            &-checked {
                background-color: $greenDark;
                border-color: $white;
            }
        }
    }

    .ant-radio-group {
        display: flex;

        span {
            font-size: 13px;
        }
    }

    .ant-radio-checked .ant-radio-inner {
        border-color: $greenPrimary;
    }

    .ant-radio-inner::after {
        background-color: $greenPrimary;
    }

    span {
        color: $greenDark;
        font-weight: 600;
    }

    .filter-radios {
        margin: 15px 0;
    }

    .picker-panel {
        display: flex;
        justify-content: space-between;

        .ant-picker {
            border: none;
            box-shadow: none;
        }

        .picker-input {
            background-color: $white;
            overflow: hidden;
            border-radius: 15px;
            padding: 0 7px;
        }
    }

    .weather-panel {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $greenPrimary;
        margin-top: 20px;
        border-radius: 13px;
        padding: 15px 5px;
        border: solid 0.5px $white;
        color: $white;
        font-weight: 500;
        font-size: 14px;
        z-index: 2;

        &-modal {
            position: absolute;
            width: 30%;
            left: 5%;
            top: 5%;
        }

        span {
            color: $white;
        }

        .weather-padding {
            padding: 3px 15px;
        }

        svg {
            margin-right: 8px;
        }

        .weather-panel-humidity {
            border-right: 1px solid $white;
        }
    }

    .arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: inline-block;
        z-index: 2;
        font-size: 32px;
        transition: .3s ease-in-out;

        svg {
            transition: .3s ease-in-out;
            color: $white;
            font-size: 32px;

            &:hover {
                color: $greyWhite;
            }
        }
    }

    .right-arrow {
        right: 0%;
        border-radius: 50% 0 0 50%;
    }

    .left-arrow {
        left: 0%;
        border-radius: 0% 50% 50% 0;
    }

    .arrow-modal {
        background: #aeaeae;
        padding: 20px;

        &:hover {
            background-color: $greyLight;
            color: $greyWhite;
        }
    }
}

.panel-temp {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 360px;
    background-color: $greyOpacity;
    backdrop-filter: blur(13px);
    @include box-shadow-custom(0, 2, 4, 0, 0, 0, .3);
    padding: 10px 0px 20px 40px;
    border-radius: 4px;
    color: $greyDark;

    ul {
        vertical-align: middle;
        margin: 0;
        padding: 0;

        li {
            display: inline-block;
            list-style-type: none;
            min-width: 20px;
            height: 15px;
            text-align: center;
        }
    }

    h2 {
        @include bold-text;
        color: $greenDark;
    }
}

.bloc-form-user {
    background-image: url('../images/settings-web/user-illust.png');
    background-repeat: no-repeat;
    background-position: 80% center;
}

.footer-logo {
    position: absolute;
    bottom: 0;
    width: 100%;
    min-height: 30px;
    padding-top: 30px;
    text-align: center;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(112, 118, 112, 0.29) 14%, rgba(113, 120, 113, 0.542) 25%);
    border-radius: 0px;
}

.block-menu {
    position: absolute;
    width: 50%;
    min-height: 600px;
    top: 50%;
    left: 50%;
    margin: -300px -15%;
    background-image: url('../images/settings-web/menu-settings.png');
    background-repeat: no-repeat;
    background-position: center bottom;
}

.menu-settings {
    display: inline-block;
    width: 250px;
    height: 70px;
    border-radius: 15px;
    margin: 10px;
    padding: 10px;
    text-align: center;
    box-shadow: 3px 2px 5px rgba(0, 0, 0, 0.3);
    @include box-shadow-custom(3, 2, 5, 0, 0, 0, .3);
    background-color: $white;
    cursor: pointer;
}

.menu-picto {
    display: inline-block;
    vertical-align: center;
    font-size: 25px;
    margin-right: 20px;
    height: 40px;
    line-height: 45px;

    &+span {
        display: inline-block;
        vertical-align: center;
        height: 40px;
        line-height: 45px;
    }
}

.header {
    position: relative;
    height: 90px;
    width: 100%;
    background-color: $white;


    &.header-bg {
        background-image: url('../images/bg-header.png');
        background-position: right;
        background-repeat: no-repeat;
        background-size: cover;
    }

    &.header-test {
        margin-top: -90px;

        .right-content {
            top: 90px;
        }
    }

    .right-content {
        position: absolute;
        right: 4%;
        top: 0;
    }


    span {
        font-size: 30px;
        transform: translateY(-50%);
        color: #065c55;
        font-weight: 600;
    }

    .domain {
        position: absolute;
        height: 55px;
        width: 55px;
        right: 0;
        top: 5vh;
        text-align: center;
        z-index: 1;
        margin: 0;
        padding: 0;

        img {
            object-fit: scale-down;
            width: 55px;
            height: 56, 5px;
            border-radius: 50%;
            box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.3);
            @include box-shadow-custom(3, 3, 8, 0, 0, 0, .3);
        }

    }
}

.slide-article {
    @include transparent-blur();

    @include responsive(small) {
        height: 480px;
        margin: 20px 0;
    }

    height: 450px;
    padding: 20px;

    .slide-cam {
        width: 100%;
        height: 180px;
        margin: 10px 5px;

        @include responsive(small) {
            .thumb-slide {
                border-radius: 30px;
                overflow: hidden;
                height: 150px;
                margin: 0 5px;
                cursor: pointer;

                img {
                    width: 100%;
                    height: 200px;
                }
            }
        }

        .thumb-slide {
            border-radius: 30px;
            overflow: hidden;
            height: 150px;
            margin: 0 5px;
            cursor: pointer;

            img {
                width: 100%;
                height: 150px;
            }
        }
    }
}

.ant-modal {
    position: absolute;
    right: 0;
    left: 0;

    .ant-modal-content {
        height: 100%;
        border-radius: 15px;
        overflow: hidden;
    }

    .ant-modal-body {
        height: 100%;
        padding: 0;

        .slide-full {

            .info-bloc {
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                width: 100%;

                .weather-panel-modal {
                    position: absolute;
                }
            }
        }
    }

    .ant-modal-close {
        top: 2%;
        right: 2%;
        border-radius: 50%;
        background-color: $darkColor;
        transition: .3s ease-in-out;

        &:hover {
            background-color: #2f2f2f61;
        }
    }

    .ant-modal-close-x {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;

        svg {
            color: $white;
            font-size: 14px;
        }
    }

    .modal-title {
        padding: 10px;
        color: $greenDark;
        @include bold-text;
        font-size: 20px;
        z-index: 20;
    }
}

.weather-panel {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $greenPrimary;
    margin-top: 20px;
    border-radius: 13px;
    padding: 15px 5px;
    border: solid 0.5px $white;
    color: $white;
    font-weight: 500;
    font-size: 14px;
    z-index: 2;

    &-modal {
        width: 400px;
    }

    span {
        color: $white;
    }

    .weather-padding {
        padding: 3px 15px;
    }

    svg {
        margin-right: 8px;
    }

    .weather-panel-humidity {
        border-right: 1px solid $white;
    }
}

.select-gallery {
    width: 250px;

    &.ant-select {
        .ant-select-selector {
            height: 35px;
            border-radius: 10px;
        }
    }
}

.statistics {
    padding: 0 10px;

    .statistics-form {
        display: flex;
        justify-content: space-between;
        width: 90%;

        .btn-predict {
            background-color: $greenPrimary;
            color: $white;
            margin-top: 20px;
            border-radius: 10px;
        }
    }

    .ant-select {
        min-width: 200px;
    }

    .spin {
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
    }

    .btn-back {
        display: flex;
        align-items: center;
        margin-top: 40px;
        background-color: transparent;
        border: none;
        transition: .3s;
      }

      .ant-btn-default:hover {
        color: $greenPrimary;
      }

      .ant-tabs-tab, .ant-tabs-tab-active .ant-tabs-tab-btn {
        color: $greenPrimary;
    }

      .ant-tabs-ink-bar {
        background: $greenPrimary;
    }

      .anticon-arrow-left {
        font-size: 20px;
        margin-right: 8px;
      }
      .list {
        padding: 30px;
        margin: 40px auto;
        max-width: 900px;
        min-width: 200px;
        @include transparent-blur();
        display: flex;
        flex-direction: column;
    
        .list-header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .link-csv {
                color: inherit;
            }
        }
    }
}
.globalMap {
        padding: 30px;
        margin: 40px auto;
        width: 90%;
        @include transparent-blur();
}

.globalMap-spin {
    width: 100%;
    text-align: center;
}